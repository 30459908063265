<template>
  <div class="content">
    <template>
      <base-loading v-if="ready" />
      <div v-else>
        <crud-list
          v-model="descontosCategoria"
          class="pt-1"
          :style="
            $vuetify.breakpoint.xs
              ? 'padding: 12px; margin: 4px'
              : 'padding: 0; margin: 0 12px 12px 12px'
          "
          :headers="headers"
          :actions="actions"
          :permission="'list_unidade_negocio'"
          :sort-desc="[false]"
          :item-height="'560'"
          :slots="['item.desc_gerente', 'item.desc_supervisor', 'item.desconto', 'item.parcelas']"
        >
          <template v-slot:[`item.desconto`]="{ item }">
            <span>
              {{ item.desconto ? currencyFormatter(item.desconto) : '' }}
            </span>
          </template>
          <template v-slot:[`item.desc_gerente`]="{ item }">
            <span>
              {{ item.desc_gerente ? currencyFormatter(item.desc_gerente) : '' }}
            </span>
          </template>
          <template v-slot:[`item.desc_supervisor`]="{ item }">
            <span>
              {{ item.desc_supervisor ? currencyFormatter(item.desc_supervisor) : '' }}
            </span>
          </template>
          <template v-slot:[`item.parcelas`]="{ item }">
            <span>
              {{ item.parcelas ? currencyFormatter(item.parcelas) : null }}
            </span>
          </template>
          <template
            v-if="hasPermission('inserir_unidade_negocio')"
            v-slot:btnCadastro
          >
            <v-col>
              <v-btn
                class="text-none text-white"
                block
                color="primary"
                title="Cadastrar nova"
                rounded
                @click="() => NEW_UNIDADE(id)"
              >
                <v-icon
                  dark
                  left
                >
                  mdi-file-plus
                </v-icon>
                Cadastrar
              </v-btn>
            </v-col>
            <unidade-negocio-configuracion ref="confForm" />
          </template>
        </crud-list>
      </div>
    </template>
  </div>
</template>
<script>
  import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
  import DescontosCategoriaStore, { BOOTSTRAP, NEW_UNIDADE, EDIT_UNIDADE, DELETE_UNIDADE } from '@/store/modules/descontosCategoria'
  import UnidadeNegocioConfiguracion from './UnidadeNegocioConfiguracion.vue'
  import DescontoCategoriaFormStore, { CLOSE } from '@/store/modules/forms/descontoCategoria'
  import { sortCurrency } from '../../utils/sorts'
  import { currencyFormatter } from '../../utils/formatter'
  export default {
    name: 'UnidadeNegocioConfiguracionList',
    components: {
      UnidadeNegocioConfiguracion,
    },
    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        currencyFormatter,
        headers: [
          { text: '', sortable: false, groupable: false, filterable: false, value: 'actions', width: '20px' },
          { align: 'left', sortable: true, groupable: true, filterable: true, text: 'Categoria do Produto', value: 'categoria.nome', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, filterable: true, text: 'Produto', value: 'produto.nome', width: 'auto' },
          { align: 'right', sortable: true, groupable: false, filterable: false, text: '% Desconto Técnico', value: 'desconto', width: 'auto' },
          { align: 'right', sortable: true, groupable: false, filterable: false, text: '% Desconto Gerente', value: 'desc_gerente', width: 'auto' },
          { align: 'right', sortable: true, groupable: false, filterable: false, text: '% Desconto Supervisor', value: 'desc_supervisor', width: 'auto' },
          { align: 'right', sortable: true, groupable: true, filterable: false, text: 'Plano de Pagamento', value: 'plano_pagamento', width: 'auto' },
          { align: 'right', sortable: true, groupable: false, filterable: false, text: 'Parcela Mínima', value: 'parcelas', width: 'auto', sort: sortCurrency },
        ],
        actions: [
          {
            title: 'Editar a Configuração',
            color: 'info darken-3',
            click: (item) => this.EDIT_UNIDADE(item),
            icon: 'mdi-pencil',
            permission: 'editar_unidade_negocio',
          },
          {
            title: 'Excluir a Configuração',
            color: 'red darken-3',
            click: (item) => this.DELETE_UNIDADE(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_unidade_negocio',
          },
        ].filter((action) =>
          this.$store.getters.hasPermission(action.permission),
        ),
      }
    },
    computed: {
      ...mapState('descontosCategoria', ['ready', 'descontosCategoria']),
      ...mapState('form/descontoCategoria', ['dialog']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!this.$store.state.form.descontoCategoria) { this.$store.registerModule(['form', 'descontoCategoria'], DescontoCategoriaFormStore) }
      if (!this.$store.hasModule('descontosCategoria')) { this.$store.registerModule('descontosCategoria', DescontosCategoriaStore) }
      this.BOOTSTRAP({ id: this.id })
      this.$store.watch(('dialog', (val) => {
        val || this.CLOSE()
      }))
    },
    methods: {
      ...mapActions('descontosCategoria', [BOOTSTRAP, NEW_UNIDADE, EDIT_UNIDADE, DELETE_UNIDADE]),
      ...mapMutations('form/descontoCategoria', [CLOSE]),
    },
  }
</script>
