<template>
  <v-dialog
    v-model="dialog"
    max-width="1500px"
    scrollable
    hide-overlay
    persistent
  >
    <v-card min-height="300px">
      <v-toolbar
        color="primary"
        dark
      >
        <span
          v-if="loading"
          class="headline"
        > Carregando Dados </span>
        <span
          v-else
          class="headline"
        > Configuração de Desconto/Parcelamento </span>
        <v-spacer />
        <v-btn
          icon
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <base-loading v-if="loading" />
      <v-card-text v-else>
        <v-container fluid>
          <v-form
            ref="confform"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                sm="5"
              >
                <v-autocomplete
                  v-model="categoria_id"
                  :items="categories"
                  item-text="nome"
                  item-value="id"
                  class="d-flex justify-space-between"
                  label="Categoria do Produto *"
                  :rules="[rules.required]"
                  clearable
                  dense
                  autocomplete="nope"
                  outlined
                  rounded
                />
              </v-col>
              <v-col
                cols="12"
                sm="7"
              >
                <v-autocomplete
                  v-model="produto_id"
                  :items="produtosDisponiveis(categoria_id)"
                  item-text="nome"
                  item-value="id"
                  item-disabled="disabled"
                  class="d-flex justify-space-between"
                  label="Produto"
                  clearable
                  dense
                  autocomplete="nope"
                  outlined
                  rounded
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="2"
              >
                <base-vuetify-money
                  v-model="desconto"
                  class="centered-input"
                  :options="money"
                  dense
                  label="% Desconto Técnico"
                  placeholder="10%"
                  validate-on-blur
                  outlined
                  rounded
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="2"
              >
                <base-vuetify-money
                  v-model="desc_supervisor"
                  class="centered-input"
                  :options="money"
                  dense
                  label="% Desconto Supervisor"
                  placeholder="10%"
                  validate-on-blur
                  outlined
                  rounded
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="2"
              >
                <base-vuetify-money
                  v-model="desc_gerente"
                  class="centered-input"
                  :options="money"
                  dense
                  label="% Desconto Gerente"
                  placeholder="10%"
                  validate-on-blur
                  outlined
                  rounded
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="plano_pagamento"
                  dense
                  type="number"
                  min="0"
                  max="12"
                  autocomplete="off"
                  label="Plano de Pagamento"
                  placeholder="(1 a 12 vezes)"
                  :rules="[value => (value > 0 && value <= 12) || 'O valor deve estar entre 1 e 12 vezes', rules.numeric]"
                  validate-on-blur
                  outlined
                  rounded
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <base-vuetify-money
                  v-model="parcelas"
                  :options="money"
                  :disabled="plano_pagamento <= 0"
                  maxlength="10"
                  label="Parcela Mínima"
                  validate-on-blur
                  dense
                  outlined
                  rounded
                />
              </v-col>
            </v-row>
            <v-row
              justify="center"
              justify-sm="end"
            >
              <v-col cols="auto">
                <v-btn
                  color="success"
                  class="text-none text-white mr-3"
                  :disabled="desabilitaBotao"
                  rounded
                  @click="submitForm"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  {{ isEditing ? "Salvar" : "Incluir" }}
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  class="text-none text-white"
                  color="error"
                  rounded
                  @click="cancel"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-minus-circle
                  </v-icon>
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import { BOOTSTRAP_PROFILE, CLOSE, SUBMIT } from '@/store/modules/forms/descontoCategoria'
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  export default {
    name: 'UnidadeNegocioConfiguracion',
    data () {
      return {
        valid: false,
        rules: rules,
        snackbar: { message: '', show: false },
        money: {
          locale: 'pt-BR',
          precision: 2,
        },
      }
    },
    computed: {
      ...mapState('descontosCategoria', ['descontosCategoria']),
      ...mapState('form/descontoCategoria', ['products', 'categories', 'loading', 'isEditing', 'dialog']),
      ...mapFields('form', [
        'detail.unidade_negocio_id',
        'detail.categoria_id',
        'detail.produto_id',
        'detail.desconto',
        'detail.desc_supervisor',
        'detail.desc_gerente',
        'detail.plano_pagamento',
        'detail.parcelas',
      ]),
      filteredProdutos () {
        const Produtos = this.descontosCategoria.filter(p => p.produto !== null).map(f => f.produto.id)
        return this.products.map(prod => ({ ...prod, disabled: Produtos.includes(prod.id) }),
        )
      },
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    methods: {
      ...mapMutations('form/descontoCategoria', [CLOSE]),
      ...mapActions('form/descontoCategoria', [BOOTSTRAP_PROFILE, SUBMIT]),
      cancel () {
        this.CLOSE()
      },
      produtosDisponiveis (nCategoria) {
        const result = this.filteredProdutos.filter(el => el.categoria.id === nCategoria)
        return result
      },
      submitForm () {
        const categoria = this.descontosCategoria.filter(el => el.categoria.id === this.categoria_id)
        const duplicado = categoria.filter(el => (el.produto === null ? el.produto : el.produto.id) === (this.produto_id === '' || this.produto_id === undefined ? null : this.produto_id))

        if (duplicado.length && !this.isEditing) {
          this.snackbar = {
            show: true,
            message:
              'Categoria ou produto já cadastrado na configuração!',
          }
          return null
        }

        if (!this.$refs.confform.validate()) {
          this.snackbar = {
            show: true,
            message:
              'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT()
      },
    },
  }
</script>
<style lang="css">
.centered-input input {
      text-align: right;
      font-weight: bold;
    }
</style>
