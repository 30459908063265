import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import Swal from 'sweetalert2'
import { unidadeNegocioTransformer } from '@/utils/transformers'
import { UnidadeNegocio } from '@/models/UnidadeNegocio'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const LOAD_GESTORES = 'LOAD_GESTORES'
export const LOAD_REGIONAIS = 'LOAD_REGIONAIS'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    gestores: [],
    regionais: [],
    roles: [],
    isEditing: false,
    step: 1,
    loading: true,
    dialog: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
  gestores: state => state.gestores,
  regionais: state => state.regionais,
  isEditing: state => state.isEditing,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id }) {
    commit(RESET_STATE)
    commit('SET_STATE', { loading: true })
    dispatch('form/BOOTSTRAP_FORM', { model: new UnidadeNegocio() }, { root: true })
    await dispatch(LOAD_GESTORES)
    await dispatch(LOAD_REGIONAIS)
    if (id) {
      commit(SET_STATE, { dialog: false, isEditing: true })
      await api.getEntidade('unidadeNegocio', id).then(response => {
        commit(SET_STATE, { loading: false })
        dispatch('form/BOOTSTRAP_FORM', { model: new UnidadeNegocio(unidadeNegocioTransformer(response.data)) }, { root: true })
      })
    } else {
      commit(SET_STATE, { dialog: true, loading: false, isEditing: false })
    }
  },
  async [LOAD_GESTORES] ({ dispatch, commit }) {
    await api.listEntidade('gestor').then(
      gestores => {
        commit(SET_STATE, { gestores })
      },
    )
  },
  async [LOAD_REGIONAIS] ({ dispatch, commit }) {
    await api.listEntidade('regional').then(
      regionais => {
        commit(SET_STATE, { regionais })
      },
    )
  },
  async [SUBMIT] ({ commit, dispatch, state, rootState }, { router, user }) {
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        const form = { ...rootState.form.data };
        (() => {
          commit('SET_STATE', { loading: true })
          return state.isEditing
            ? api.salvarEntidade(form, 'unidadeNegocio', form.id)
            : api.cadastrarEntidade(form, 'unidadeNegocio')
        })().then(
          data => {
            window.Toast.fire(state.isEditing
              ? 'Unidade de Negócio atualizada com sucesso!'
              : 'Unidade de Negócio cadastrada com sucesso!', '', 'success')
            dispatch('unidadesNegocio/BOOTSTRAP', null, { root: true })
            router.push({
              path: `/${user.get().role}/unidadesnegocio`,
            })
          },
          error => {
            window.Toast.fire(error.errors ? error.errors : error.message, '', 'error')
            router.push({
              path: `/${user.get().role}/unidadesnegocio`,
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
