import { v4 } from 'uuid'

export class DescontoCategoria {
  constructor (
    {
      id,
      produto_id,
      unidade_negocio_id,
      categoria_id,
      desconto,
      desc_supervisor,
      desc_gerente,
      plano_pagamento,
      parcelas,
    } = {
      id: v4(),
      produto_id: '',
      unidade_negocio_id: '',
      categoria_id: '',
      desconto: '',
      desc_supervisor: '',
      desc_gerente: '',
      plano_pagamento: '',
      parcelas: '',
    },
  ) {
    this.id = id
    this.produto_id = produto_id
    this.unidade_negocio_id = unidade_negocio_id
    this.categoria_id = categoria_id
    this.desconto = desconto
    this.desc_supervisor = desc_supervisor
    this.desc_gerente = desc_gerente
    this.plano_pagamento = plano_pagamento
    this.parcelas = parcelas
  }
}

export function createDescontoCategoria (data) {
  return Object.freeze(new DescontoCategoria(data))
}
